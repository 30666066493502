.loader {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    .loader-bg {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.7);
    }
    .loader-message {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        text-align: center;
        width: 100px;
        height: 20px;
        margin: auto;
    }
}
