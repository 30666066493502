.react-expand-collapse__content {
    position: relative;
    overflow: hidden;
}

.react-expand-collapse__body {
    display: inline;
}

/* expand-collapse button */
.react-expand-collapse__button {
    color: #22a7f0;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #fff;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.react-expand-collapse__button:before {
    content: "";
    position: absolute;
    top: 0;
    left: -20px;
    width: 20px;
    height: 100%;
    background: linear-gradient(to right, transparent 0, #fff 100%);
}

/* expanded state */
.react-expand-collapse--expanded .react-expand-collapse__button {
    padding-left: 5px;
    position: relative;
    bottom: auto;
    right: auto;
}

.react-expand-collapse--expanded .react-expand-collapse__button:before {
    content: none;
}

.layout-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    min-height: 100vh;
    .layout-col {
        display: flex;
        flex-direction: column;
    }
}

.result-area {
    background-color: #F7F7F7;
    background-image: linear-gradient(to bottom, #cccaca, transparent);
    background-size: auto 100px;
    background-repeat: no-repeat;
    padding: 10px;
    height: calc(100% - 101px);
}